import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      bilen: file(relativePath: { eq: "content/brandis-bilen-irl.jpg" }) {
        childImageSharp {
          fixed(width: 490) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      play: file(relativePath: { eq: "content/play-with-boxes.jpg" }) {
        childImageSharp {
          fixed(width: 490) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Om Brandbilen" />
      <Layout>
        <div className="py-16 bg-warm-grey-50 overflow-hidden lg:py-24">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-warm-grey-900 sm:text-4xl sm:leading-10">
                Brandbilen
              </h3>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-warm-grey-500">
                I slutet på 80-talet, närmare bestämt 1989, var det en grupp entusiastiska föräldrar som började fundera på att starta ett föräldrakooperativt dagis.
              </p>
            </div>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-extrabold text-warm-grey-900 tracking-tight sm:text-3xl sm:leading-9">
                  Historien fortsätter
                </h4>
                <p className="mt-3 text-lg leading-7 text-warm-grey-500">
                  Efter att ha informerat och satt upp intresselistor så var det i slutändan nio familjer som ville vara med och starta upp ett kooperativ. Då flera av dem arbetade på brandstationen så föll det sig naturligt att det skulle heta Daghemmet Brandbilen.
                </p>
                <p className="mt-3 text-lg leading-7 text-warm-grey-500">
                  I mer än ett år pågick arbetet för att kunna starta upp dagiset. Det var studiecirklar, möten med kommunen och olika arbetsgrupper. Lokalen bestämdes till Scoutlokalen i Gullänget. Det är för övrigt samma lokal som används idag.
                </p>
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          1990
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Brandbilen startar</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Hösten 1990 anställdes personal och i Januari 1991 var det dags för dagiset att öppna.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          1991
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Brandbilen invigs</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Den 21 januari 1991 var det stor invigning och räddningschefen klippte det rödvita bandet och förklarade Daghemmet Brandbilen öppnat.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          2011
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">20 år firas</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Det känns fantastiskt att Förskolan Brandbilen (som det nu heter) år 2011 firade 20 års jubileum. Alla familjer som var med och startade var inbjudna till en stor fest på förskolan.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                          2020
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="text-lg leading-6 font-medium text-warm-grey-900">30 år firas</h5>
                        <p className="mt-2 text-base leading-6 text-warm-grey-500">
                          Tack alla barn, föräldrar och person som under åren varit med på denna resa. Det är bara början på en fantastisk fortsättning, väl värd att fira.
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 flex">
                <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                  <defs>
                    <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                </svg>
                <Img className="relative mx-auto" fixed={data.bilen.childImageSharp.fixed} />
              </div>
            </div>

            <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="lg:col-start-2">
                  <h4 className="text-2xl leading-8 font-extrabold text-warm-grey-900 tracking-tight sm:text-3xl sm:leading-9">
                    Förskolans målsättning
                  </h4>
                  <p className="mt-3 text-lg leading-7 text-warm-grey-500">
                    Vår förskola är ett föräldrakooperativ. Det betyder att det är vi föräldrar som tillsammans med personalen driver förskolan framåt utifrån skollagen och Läroplan för förskolan.
                  </p>
                  <p className="mt-3 text-lg leading-7 text-warm-grey-500">
                    Vi satsar på en stimulerande och utmanande utemiljö. Saker man kan göra inne kan man även göra ute.
                  </p>

                  <ul className="mt-10">
                    <li>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Vara utomhus</h5>
                          <p className="mt-2 text-base leading-6 text-warm-grey-500">
                            I verksamheten prioriterar vi att låta barnen vara ute så mycket som möjligt. <em>Det finns inga dåliga väder - bara dåliga kläder</em> är en klyscha men något vi tror på. Ett exempel på hur vi arbetar för det är att alla barnen kommer i underställ på morgonen. Då går det både snabbare och enklare för personalen att låta dem gå ut och leka efter frukosten.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Äta bra mat</h5>
                          <p className="mt-2 text-base leading-6 text-warm-grey-500">
                            Förskolan följer Livsmedelsverkets nationella riktlinjer för måltider i förskolan. Vi arbetar med att inhandla lokala produkter och välja ekologiska alternativ. Vi arbetar aktivt med att öka det vegetariska utbudet och minst två gånger i veckan serveras vegetariskt. All personal på förskolan äter tillsammans med barnen där vi arbetar med sensorisk utveckling och måltidspedagogik.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-bb-red-500 text-white">
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="text-lg leading-6 font-medium text-warm-grey-900">Transparans</h5>
                          <p className="mt-2 text-base leading-6 text-warm-grey-500">
                            Utöver detta vill vi ha en transparent verksamhet där man som förälder får så god inblick som möjligt i verksamheten samt att alla, både och personal och föräldrar alla bidrar på olika sätt efter tid och förmåga.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1 flex">
                  <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                    <defs>
                      <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-warm-grey-200" fill="currentColor" />
                      </pattern>
                    </defs>
                    <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                  </svg>
                  <Img className="relative mx-auto" fixed={data.play.childImageSharp.fixed} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}



export default Page
